import { useState, useRef, forwardRef } from "react";
import { Input, InputRef, Spin } from "antd";
import { FieldRenderProps } from "react-final-form";
import cn from "classnames";
import styles from "./FloatingLabelInput.module.scss";
import { ERROR_FIELD_ } from "~/configs/consts";
import { CustomTooltip } from "../title-field/CustomTooltip";
import { LoadingOutlined } from "@ant-design/icons";
import mergeRefs from "merge-refs";
import { withMask } from "use-mask-input";

const antIcon = <LoadingOutlined className={styles.spin} spin />;

interface FloatingLabelInputProps extends Partial<FieldRenderProps<string, HTMLElement>> {
	value?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	titleField?: string;
	disabled?: boolean;
	type?: string;
	maxLength?: number;
	required?: boolean;
	isColon?: boolean;
	tooltip?: string;
	percent?: string;
	isLoading?: boolean;
	allowMultiline?: boolean;
	showCount?: boolean;
	classNameInput?: string;
	mask?: string;
}

export const FloatingLabelInput: React.FC<FloatingLabelInputProps> = ({
	input,
	meta,
	value = "",
	onChange,
	titleField,
	disabled,
	type = "text",
	maxLength,
	required = false,
	isColon = false,
	tooltip,
	percent,
	isLoading,
	allowMultiline = false,
	showCount = false,
	classNameInput,
	mask,
	...props
}) => {
	const [isFocused, setIsFocused] = useState(false);
	const hoverRef = useRef<HTMLImageElement>(null);
	const inputRef = useRef<any>(null);
	const hasError = (meta?.error || meta?.submitError) && meta?.submitFailed;
	const inputId = hasError && input ? `${ERROR_FIELD_}${input.name}` : undefined;

	const mergedInputRefs = isFocused && mask ? mergeRefs(inputRef, withMask(mask)) : inputRef;

	const handleLabelClick = () => {
		inputRef.current?.focus();
	};

	const handleLabelBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		// С маской работает не валидно при потери фокуса
		if (!mask) {
			setIsFocused(false);
		}
		input?.onBlur?.(e);
	};

	const inputAddons = (
		<div className={cn(styles.inputAddons, { [styles.inputAddonsTextArea]: allowMultiline })}>
			{isLoading && (
				<Spin indicator={antIcon} className={styles.containerSpin} style={{ right: tooltip ? '30px' : '0px' }} />
			)}
			{tooltip && (
				<CustomTooltip
					tooltip={tooltip}
					placement="topRight"
				/>
			)}
		</div>
	);

	return (
		<div className={cn(styles.inputWrapper, { [styles.focused]: isFocused || value, [styles.error]: hasError })}>
			<label onClick={handleLabelClick} className={cn({
				[styles.placeholderInput]: !allowMultiline,
				[styles.placeholderTextArea]: allowMultiline,
				[styles.text]: required,
			})}>
				{titleField}
				{isColon && ":"}
			</label>
			<div className={cn(styles.inputContainer, {
				[styles.inputContainerTextArea]: allowMultiline
			})}>
				{allowMultiline ? (
					<textarea
						{...props}
						ref={inputRef}
						value={input?.value ?? value}
						onChange={input?.onChange ?? onChange}
						onFocus={() => setIsFocused(true)}
						onBlur={handleLabelBlur}
						disabled={disabled}
						maxLength={maxLength}
						className={cn(styles.customTextArea)}
						id={inputId}
					/>
				) : (
					<input
						{...props}
						ref={mergedInputRefs}
						value={input?.value ?? value}
						onChange={input?.onChange ?? onChange}
						onFocus={() => setIsFocused(true)}
						onBlur={handleLabelBlur}
						type={type}
						disabled={disabled}
						maxLength={maxLength}
						className={cn(styles.customInput, classNameInput, { [styles.focusedInput]: isFocused })}
						style={{ paddingRight: isLoading ? '70px' : '40px' }}
						id={inputId}
					/>
				)}

				{inputAddons}

				{maxLength && showCount && (
					<div className={styles.charCount}>
						{`${value.length}/${maxLength}`}
					</div>
				)}
			</div>
		</div>
	);
};
