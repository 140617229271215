import { Field, FormSpy } from "react-final-form";
import { Divider, Spin } from "antd";
import { RadioField, CheckboxField, TitlePage, PhoneField } from "~/components";
import { composeValidators, disabledValidate, required, testRegExp } from "~/utils/validate";
import { FormField, SocialLinkSelectField, UploadFile } from "~/pages/questionnaires/shared";
import { NamesOfFieldEnum } from "~/typings/types";
import { configTitleFieldsOfCompany } from "../configTitleFields";
import { URL_TARIF } from "~/configs/consts";
import { V2Wizard } from "../../../../shared/wizard";
import { FullWidthRowFormContainer } from "~/pages/questionnaires/shared/form-field";
import { SocialLinkSelectDescription } from "../../../../shared/social-link-select-description";

import styles from "./CommonSteps.module.scss";
import { LoadingOutlined } from "@ant-design/icons";

export const PayformTechDetails = ({
  paymentMethod,
  educationalPlatform,
  productType,
  typeRequestCheckPayformName,
  callbackCheckPayformName,
  isLoadingMount,
  isLoadingLogo,
  logoFiles,
  setLogoFileId,
  setLogoFiles,
  logoError,
  setLogoError,
  COUNT_UPLOAD_LOGO_FILE,
  setIsFirstLoadingFile,
}: any) => {
  return (
    <V2Wizard.Page>
      <TitlePage
        name="Настройка платёжной страницы"
        number="4"
      />
      <Divider style={{ margin: 0 }} />
      <div className={styles.container}>
        {productType && Object.keys(productType).length && (
          <FullWidthRowFormContainer>
            <FormField
              titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.ProductType]}
              name={NamesOfFieldEnum.ProductType}
              tooltip="Выберите основной тип продукта для продажи. Если их несколько, выберите тип с наибольшей долей оборота"
              isColon={false}
              required
              shouldRenderTitleAboveField
            >
              <div className={styles.containerRadio}>
                {Object.values(productType).map(({ name, title }: any) => (
                  <Field
                    key={name}
                    name={NamesOfFieldEnum.ProductType}
                    type="radio"
                    value={name}
                    component={props => <RadioField   {...props} />}
                    validate={disabledValidate(composeValidators(required()))}
                  >
                    {title}
                  </Field>
                ))}
              </div>
            </FormField>
          </FullWidthRowFormContainer>
        )}

        <FullWidthRowFormContainer>
          <FormSpy subscription={{ values: true }}>
            {propsSpy => (
              <>
                {propsSpy.values[NamesOfFieldEnum.ProductType] === "education" && (
                  <FormField
                    titleField={
                      configTitleFieldsOfCompany[NamesOfFieldEnum.IsHaveEducationalLicense]
                    }
                    name={NamesOfFieldEnum.IsHaveEducationalLicense}
                    isColon={false}
                    required
                    shouldRenderTitleAboveField
                    styleContainerTitleField={{ width: "96%" }}
                  >
                    <div className={styles.containerRadio}>
                      <Field
                        name={NamesOfFieldEnum.IsHaveEducationalLicense}
                        type="radio"
                        value="yes"
                        component={props => <RadioField   {...props} />}
                        validate={disabledValidate(composeValidators(required()))}
                      >
                        Да
                      </Field>
                      <Field
                        name={NamesOfFieldEnum.IsHaveEducationalLicense}
                        type="radio"
                        value="no"
                        component={props => <RadioField   {...props} />}
                        validate={disabledValidate(composeValidators(required()))}
                      >
                        Нет
                      </Field>
                    </div>
                  </FormField>
                )}
              </>
            )}
          </FormSpy>
        </FullWidthRowFormContainer>

        <FullWidthRowFormContainer>
          <FormField
            titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.ProductName]}
            name={NamesOfFieldEnum.ProductName}
            required
            tooltip="Укажите название вашего проекта. Например: онлайн-марафон «Как перейти на удалённую работу»; онлайн-школа иностранного языка Easy English; тренинги личностного роста Иванова Ивана"
            validate={composeValidators(required())}

          />
        </FullWidthRowFormContainer>

        <FullWidthRowFormContainer>
          <FormField
            titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.ProductDescription]}
            name={NamesOfFieldEnum.ProductDescription}
            required
            validate={composeValidators(required())}
            showCount
            maxLength={10000}
            tooltip={`Расскажите подробнее о ваших услугах или продуктах. Добавьте 4-5 предложений, которые наиболее понятно описывают суть вашего проекта для целевой аудитории`}

            placement="left"
          />
        </FullWidthRowFormContainer>

        <FullWidthRowFormContainer>
          <FormField
            titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.PayformName]}
            name={NamesOfFieldEnum.PayformName}
            required
            addonBefore="https://"
            addonAfter=".payform.ru"
            tooltip="Придумайте название для вашей платёжной ссылки — его будут видеть покупатели. Например: prodamus.payform.ru"
            validate={composeValidators(
              required(),
              testRegExp(
                /^[a-zA-Z0-9-]{1,25}$/g,
                'Используйте латиницу, цифры и знак "-". Не более 25 символов'
              )
            )}
            typeInput="inputDefault"
            shouldRenderTitleAboveField
            typeRequestCheckPayformName={typeRequestCheckPayformName}
            onBlur={callbackCheckPayformName}
          />
        </FullWidthRowFormContainer>

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.PayformPhone]}
          name={NamesOfFieldEnum.PayformPhone}
          required
          tooltip="Это обязательное требование платёжных систем. Номер должен быть действительным"
          triggerAutofill={[NamesOfFieldEnum.PayformPhone, NamesOfFieldEnum.CrmPhone]}
        >
          <Field
            name={NamesOfFieldEnum.PayformPhone}
            subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
            validate={disabledValidate(
              composeValidators(
                required(),
                testRegExp(/^[+7]/, "Номер телефона должен начинаться на +7")
              )
            )}
            component={props => <PhoneField required titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.PayformPhone]} onlyCountries={["ru"]} {...props} />}
          />
        </FormField>

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.PayformEmail]}
          name={NamesOfFieldEnum.PayformEmail}
          tooltip="Если не хотите указывать свой email на платёжной странице, оставьте поле пустым"
          required
          validate={composeValidators(required())}
          triggerAutofill={[NamesOfFieldEnum.PayformEmail, NamesOfFieldEnum.CrmEmail]}
        />

        <FullWidthRowFormContainer>
          <SocialLinkSelectField
            titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.PayformLinks]}
            name={NamesOfFieldEnum.PayformLinks}
            required
            fieldsLinks={[
              NamesOfFieldEnum.PayformLinkSite,
              NamesOfFieldEnum.PayformLinkTg,
              NamesOfFieldEnum.PayformLinkVk,
              NamesOfFieldEnum.PayformLinkInstagram,
              NamesOfFieldEnum.PayformLinkOther,
            ]}
            descriptionContent={<SocialLinkSelectDescription />}
          />
        </FullWidthRowFormContainer>

        <FullWidthRowFormContainer>
          <FormField
            titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.PayformPaymentMethods]}
            name={NamesOfFieldEnum.PayformPaymentMethods}
            required
            isColon={false}
            shouldRenderTitleAboveField
          >
            <div className={styles.containerPaymentMethods}>
              {paymentMethod &&
                Object.values(paymentMethod).map((option: any) => (
                  <CheckboxField
                    key={option?.name}
                    fieldName={NamesOfFieldEnum.PayformPaymentMethods}
                    validate={disabledValidate(composeValidators(required()))}

                    {...option}
                  />
                ))}
              <p className={styles.descriptionPaymentMethods}>
                С подробным описанием методов оплат с комиссией, вы можете ознакомиться{" "}
                <a href={URL_TARIF} target="_blank" className={styles.textBlueLink}>
                  здесь
                </a>
              </p>
            </div>
          </FormField>
        </FullWidthRowFormContainer>

        <FullWidthRowFormContainer>
          <FormField
            titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.LogoMainFileId]}
            name={NamesOfFieldEnum.LogoMainFileId}
            tooltip="Наличие логотипа на платежной странице улучшает визуальное восприятие вашими клиентами страницы и увеличивает конверсию в оплату"
            styleContainer={{ minHeight: 150 }}
            renderError={logoError}
          >
            {isLoadingMount ? (
              <div className={styles.containerLoaderLogo}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#3d935e" }} spin />} />
              </div>
            ) : (
              <div>
                <UploadFile
                  id={NamesOfFieldEnum.LogoMainFileId}
                  uploadedFiles={logoFiles}
                  setUploadedFiles={setLogoFiles}
                  isLoadingProps={isLoadingLogo}
                  setFileid={setLogoFileId}
                  count={COUNT_UPLOAD_LOGO_FILE}
                  setError={setLogoError}
                  setIsFirstLoadingFile={setIsFirstLoadingFile}
                />
              </div>
            )}

          </FormField>
        </FullWidthRowFormContainer>

      </div>
    </V2Wizard.Page>
  );
};
