import React, { useState, type FC } from "react";

import { ClientId } from "~/typings/types";
import { AutofillFieldButton, ErrorField, TitleField } from "~/components";

import styles from "./Autocomplete.module.scss";
import { AutocompleteInput } from "./AutocompleteInput";
import { S } from "vite/dist/node/types.d-aGj9QkWt";

export type GetErrorSuggestions = {
  code: string;
  message: string;
} | null

interface Props {
  name: string;
  nameFiasId: string;
  clientId: ClientId;
  titleField?: string;
  validate?: any;
  required?: boolean;
  tooltip?: string;
  isColon?: boolean;
  styleContainerTitleField?: any;
  styleContainer?: any;
  placeholder?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  renderError?: any;
  triggerAutofill?: string[];
  triggerAutofillForAddressFiasId?: string[];
  propsSpy: any;
  urlPersonalDataAddressSuggest: string;
}

export const FormFieldAutocompleteAddress: FC<Props> = ({
  name,
  nameFiasId,
  styleContainer,
  renderError,
  triggerAutofill,
  triggerAutofillForAddressFiasId,
  ...propsAutocompleteInput
}) => {
  const [errorGetSuggestions, setErrorGetSuggestions] = useState<GetErrorSuggestions>(null);
  const customErrorField =
    renderError || (errorGetSuggestions && { has: 1, message: errorGetSuggestions.message });

  const errors = propsAutocompleteInput.propsSpy.errors;
  const nameErrorField =
    errors && errors?.[name] === "Обязательно для заполнения" ? name : nameFiasId;

  return (
    <div className={styles.containerField} style={styleContainer}>
      {/* {propsAutocompleteInput.titleField && (
        <TitleField
          name={propsAutocompleteInput.titleField}
          required={propsAutocompleteInput.required}
          tooltip={propsAutocompleteInput.tooltip}
          isColon={propsAutocompleteInput.isColon}
          styleContainer={propsAutocompleteInput.styleContainerTitleField}
        />
      )} */}

      <div className={styles.containerInput}>
        <AutocompleteInput
          {...propsAutocompleteInput}
          name={name}
          nameFiasId={nameFiasId}
          propsSpy={propsAutocompleteInput.propsSpy}
          setErrorGetSuggestions={setErrorGetSuggestions}
          customError={!!customErrorField}
        />
      </div>

      <AutofillFieldButton
        triggerAutofill={triggerAutofill}
        triggerAutofillForAddressFiasId={triggerAutofillForAddressFiasId}
      />
      <ErrorField name={nameErrorField} renderError={customErrorField} />
    </div>
  );
};