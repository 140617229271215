import { ReactNode, useEffect, FC, CSSProperties } from "react";
import { FieldRenderProps } from "react-final-form";
import { Input } from "antd";
import cn from "classnames";
import { ERROR_FIELD_ } from "~/configs/consts";
import { TypeInput } from "~/typings/types";
import { useDebounce } from "usehooks-ts";
import { FloatingLabelInput } from "../floating-label-input";

import './InputField.css';
import styles from "./InputField.module.scss";

interface InputFieldProps extends FieldRenderProps<string, HTMLElement> {
  typeInput?: TypeInput;
  mask?: string;
  typeRequestCheckPayformName?: "loading" | "error" | "errorValidateFromApi" | "success";
  maxLength?: number;
  disabled?: boolean;
  addonBefore?: ReactNode;
  addonAfter?: ReactNode;
  showCount?: boolean;
  tooltip?: string;
}

const configInput: Record<TypeInput, FC<any>> = {
  input: ({ addonBefore, addonAfter, ...props }) => <FloatingLabelInput {...props} />,
  inputDefault: ({ allowMultiline, titleField, ...props }) => <Input {...props} style={{ height: 56 }} height={56} />,
  inputNumber: (props) => <Input {...props} />,
};

const buildInputField = (typeInput: TypeInput = "input", props: any): ReactNode => {
  const Component = configInput[typeInput];
  if (!Component) {
    console.warn(`не известный инпут: ${typeInput}`);
    return null;
  }
  return <Component {...props} />;
};

export const InputField: FC<InputFieldProps> = ({
  input,
  meta,
  typeInput = "input",
  mask = "",
  typeRequestCheckPayformName,
  maxLength,
  disabled,
  addonBefore,
  addonAfter,
  showCount,
  onBlur,
  tooltip,
  ...props
}) => {
  const isLoading = typeRequestCheckPayformName === "loading";
  const hasError =
    typeRequestCheckPayformName === "error" ||
    typeRequestCheckPayformName === "errorValidateFromApi" ||
    ((meta?.error || meta?.submitError) && meta?.submitFailed);
  const inputId = hasError ? `${ERROR_FIELD_}${input.name}` : undefined;

  const debouncedValue = useDebounce<string | undefined>(input?.value, 2000);

  const stylesInput: CSSProperties = {
    ...props.style,
    borderColor: hasError ? "#E41D36" : "#E6E6E6",
    color: hasError ? "#E41D36" : "#000",
    width: "100%",
  };

  useEffect(() => {
    if (onBlur && debouncedValue) {
      onBlur(debouncedValue);
    }
  }, [debouncedValue]);

  return (
    <div className={cn({ [styles.inputWrapperError]: hasError && !isLoading })}>
      {buildInputField(typeInput, {
        ...input,
        ...props,
        style: stylesInput,
        id: inputId,
        className: styles.input,
        maxLength,
        disabled,
        addonBefore,
        addonAfter,
        showCount,
        tooltip,
        mask,
        onFocus: (e: React.FocusEvent<HTMLElement, Element>) => {
          input.onFocus(e);
        },
      })}
    </div>
  );
};
