import { useState } from "react";
import { FormSpy } from "react-final-form";
import { CheckboxField, TitlePage } from "~/components";
import { composeValidators, disabledValidate, notEmptyValue, required, testRegExp } from "~/utils/validate";
import { Divider } from "antd";
import {
  Wizard,
  FormField,
  CustomInnField,
  FormFieldAutocompleteAddress,
  FormFieldBic
} from "~/pages/questionnaires/shared";
import { ClientId, NamesOfFieldEnum, OrgTypeEnum } from "~/typings/types";
import { configTitleFieldsOfBank, configTitleFieldsOfCompany } from "../configTitleFields";
import {
  GET_BANK_INFO,
  PERSONAL_DATA_ADDRESS_SUGGEST
} from "~/pages/questionnaires/v1/customer/api/apiUrls";

import styles from "~/pages/questionnaires/shared/Shared.module.scss";

interface Props {
  clientId: ClientId;
  orgType: OrgTypeEnum;
}

export const CompanyDetails = ({ clientId, orgType }: Props) => {
  /** До проверки ИНН все поля в этом шаге должны быть disable */
  const [isDisabledFieldsByInn, setIsDisabledFieldsByInn] = useState(true);

  return (
    <Wizard.Page>
      <TitlePage name="Укажите свои реквизиты" number="3" />
      <Divider style={{ margin: 0 }} />
      <div className={styles.container}>
        <CustomInnField
          innLength={10}
          orgType={orgType}
          clientId={clientId}
          configNameFields={configTitleFieldsOfCompany}
          setIsDisabledFieldsByInn={setIsDisabledFieldsByInn}
        />

        <FormSpy>
          {propsSpy => {
            return (
              <FormFieldBic
                titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.BankBic]}
                name={NamesOfFieldEnum.BankBic}
                required
                validate={composeValidators(
                  required(),
                  testRegExp(/^04(\d){7}$/g, 'БИК должен начинаться на "04" и состоять из 9 цифр')
                )}
                disabled={isDisabledFieldsByInn}
                configNameFieldsModalSuggestion={configTitleFieldsOfBank}
                propsSpy={propsSpy}
                clientId={clientId}
                tooltip="БИК — уникальный код банка. Он состоит из девяти цифр"
                urlGetBankInfo={GET_BANK_INFO}
              />
            );
          }}
        </FormSpy>

        {
          // Костыль для валидации поля БИК, разобраться почему не выводится ошибка валидации БИК без этого скрытого поля
        }
        <div style={{ position: 'absolute', top: 0, left: 0, width: 0, height: 0, overflow: 'hidden' }}>
          <FormField
            titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.BankBic]}
            name={NamesOfFieldEnum.BankBic}
            required
            validate={composeValidators(
              required(),
              testRegExp(/^04(\d){7}$/g, 'БИК должен начинаться на "04" и состоять из 9 цифр')
            )}
          />
        </div>


        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.BankName]}
          name={NamesOfFieldEnum.BankName}
          required
          validate={composeValidators(required())}
        // disabled={isDisabledFieldsByInn}
        />

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.Ogrn]}
          name={NamesOfFieldEnum.Ogrn}
          maxLength={13}
          required
          validate={composeValidators(
            required(),
            testRegExp(
              /^[15]\d{12}$/g,
              'ОГРН должен начинаться на "1" или "5" и состоять из 13 цифр'
            )
          )}
        // disabled={isDisabledFieldsByInn}
        />

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.Kpp]}
          name={NamesOfFieldEnum.Kpp}
          maxLength={9}
          required
          validate={composeValidators(
            required(),
            testRegExp(/^(\d){9}$/g, "КПП должен состоять из 9 цифр")
          )}
        // disabled={isDisabledFieldsByInn}
        />

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.SettlementAccount]}
          name={NamesOfFieldEnum.SettlementAccount}
          maxLength={20}
          mask="40799999999999999999"
          required
          validate={composeValidators(
            required(),
            testRegExp(
              /^407(\d){17}$/g,
              'Номер расчётного счёта должен начинаться на "407" и состоять из 20 цифр'
            )
          )}
        // disabled={isDisabledFieldsByInn}
        />

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.CorrespondentAccount]}
          name={NamesOfFieldEnum.CorrespondentAccount}
          maxLength={20}
          mask="30199999999999999999"
          required
          validate={composeValidators(
            required(),
            testRegExp(
              /^301(\d){17}$/g,
              'Номер корреспондентского счёта должен начинаться на "301" и состоять из 20 цифр'
            )
          )}
        // disabled={isDisabledFieldsByInn}
        />

        <FormSpy>
          {propsSpy => {
            return (
              <FormFieldAutocompleteAddress
                titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.LegalAddress]}
                name={NamesOfFieldEnum.LegalAddress}
                nameFiasId={NamesOfFieldEnum.LegalAddressFiasId}
                required
                validate={composeValidators(required(), notEmptyValue())}
                // disabled={isDisabledFieldsByInn}
                propsSpy={propsSpy}
                clientId={clientId}
                urlPersonalDataAddressSuggest={PERSONAL_DATA_ADDRESS_SUGGEST}

              />
            );
          }}
        </FormSpy>

        {/*<FormField name={NamesOfFieldEnum.IsWantCreatePayform} isColon={false}>*/}
        {/*  <div>*/}
        {/*    <CheckboxField*/}
        {/*      key={NamesOfFieldEnum.IsWantCreatePayform}*/}
        {/*      fieldName={NamesOfFieldEnum.IsWantCreatePayform}*/}
        {/*      disabled={isDisabledFieldsByInn}*/}
        {/*      validate={disabledValidate(composeValidators())}*/}
        {/*       */}
        {/*      title={configTitleFieldsOfCompany[NamesOfFieldEnum.IsWantCreatePayform]}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</FormField>*/}
      </div>
    </Wizard.Page>
  );
};
