import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";

dayjs.extend(customParseFormat);
dayjs.extend(utc);

export const getFormattedDate = (date: dayjs.Dayjs | string, template = "DD.MM.YYYY") => {
  return date ? dayjs(date).format(template) : "";
};

export const getFormattedDateForBackend = (date?: string) => {
  if (!date) return "";

  const parsedDate = dayjs(date, "DD.MM.YYYY", true);
  return parsedDate.isValid() ? parsedDate.utc(true).toISOString() : "";
};
