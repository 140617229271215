// @ts-nocheck
import { Dispatch, SetStateAction } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Spin } from "antd";
import cn from "classnames";
import { TitleField, TitlePage } from "~/components";
import {
  RulesFiles,
  FormField,
  Wizard,
  UploadFile,
  AcceptPolitics
} from "~/pages/questionnaires/shared";
import { AttachmentsDetailsError, NamesOfFieldEnum, OrgTypeEnum } from "~/typings/types";
import { configTitleFieldsOfSelfEmployed } from "../configTitleFields";
import { useForm } from 'react-final-form';

import "~/pages/questionnaires/shared/styles.css";
import styles from "~/pages/questionnaires/shared/Shared.module.scss";
import stylesStep from "./CommonSteps.module.scss";

interface Props {
  isLoadingMount: boolean;
  isLoadingLogo: boolean;
  isLoadingPassport: boolean;
  logoFiles: File[];
  setLogoFileId: Dispatch<SetStateAction<number[]>>;
  setLogoFiles: Dispatch<File[]>;
  passportFiles: File[];
  setPassportFileId: Dispatch<SetStateAction<number[]>>;
  setPassportFiles: Dispatch<File[]>;
  logoError: AttachmentsDetailsError;
  passportError: AttachmentsDetailsError;
  setLogoError: Dispatch<AttachmentsDetailsError>;
  setPassportError: Dispatch<AttachmentsDetailsError>;
  COUNT_UPLOAD_LOGO_FILE?: number;
  COUNT_UPLOAD_PASSPORT_FILE?: number;
  isAcceptPolitics: boolean;
  setIsAcceptPolitics: Dispatch<SetStateAction<boolean>>;
  acceptPoliticsError: AttachmentsDetailsError;
  setAcceptPoliticsError: Dispatch<SetStateAction<AttachmentsDetailsError>>;
  setIsFirstLoadingFile: Dispatch<boolean>;
  orgType?: OrgTypeEnum;
}

export const AttachmentsDetails = ({
  isLoadingMount,
  isLoadingLogo,
  isLoadingPassport,
  logoFiles,
  setLogoFileId,
  setLogoFiles,
  passportFiles,
  setPassportFileId,
  setPassportFiles,
  logoError,
  passportError,
  setLogoError,
  setPassportError,
  COUNT_UPLOAD_LOGO_FILE,
  COUNT_UPLOAD_PASSPORT_FILE,
  isAcceptPolitics,
  setIsAcceptPolitics,
  acceptPoliticsError,
  setAcceptPoliticsError,
  setIsFirstLoadingFile,
  orgType,
  passportMainFiles,
  setPassportMainFiles,
  passportRegistrationFiles,
  setPassportRegistrationFiles,
  passportMainFilesError,
  setPassportMainFilesError,
  passportRegistrationFilesError,
  setPassportRegistrationFilesError,
  setPassportMainFileId,
  isLoadingPassportMainFileId,
  setPassportRegistrationFileId,
  isLoadingPassportRegistrationFile,
}: Props) => {
  const values = useForm().getState().values;
  const isHidePayformDetailsStep = values?.[NamesOfFieldEnum.IsWantCreatePayform];
  const currentPage = isHidePayformDetailsStep ? '5' : '4';

  return (
    <Wizard.Page>
      <TitlePage name="Загрузите необходимые документы" number={currentPage} />
      <Divider style={{ margin: 0 }} />
      <div className={cn(styles.container, styles.containerUpload)}>
        {isLoadingMount ? (
          <div className={styles.containerLoader}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#3d935e" }} spin />} />
          </div>
        ) : (
          <div className={stylesStep.containerItem}>
            <TitleField
              name={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.PassportFileId]}
              required
            />
            <div className={stylesStep.itemHead}>
              <p style={{ fontSize: '12px', color: '#8c8c8c', marginBottom: 4 }}>ВАЖНО:</p>
              <ul className={stylesStep.listRequired}>
                <li>Необходимо предоставить скан/фото 2-3 страницы паспорта и страницу с действующей пропиской.</li>
                <li>Данные должны быть четко видны (без засветов, закрытых элементов), особенно серия и номер паспорта.</li>
              </ul>
            </div>
            <div className={stylesStep.itemContent}>
              <FormField
                titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.PassportFileId]}
                name={NamesOfFieldEnum.PassportMainFileId}
                required
                renderError={passportMainFilesError}
              >
                <div>
                  <UploadFile
                    id={NamesOfFieldEnum.PassportMainFileId}
                    uploadedFiles={passportMainFiles}
                    setUploadedFiles={setPassportMainFiles}
                    isLoadingProps={isLoadingPassportMainFileId}
                    setFileid={setPassportMainFileId}
                    count={1}
                    setError={setPassportMainFilesError}
                    setIsFirstLoadingFile={setIsFirstLoadingFile}
                    contentText="Главный разворот"
                  />
                </div>
              </FormField>
              <FormField
                titleField={""}
                name={NamesOfFieldEnum.PassportRegistrationFileId}
                required
                renderError={passportRegistrationFilesError}
              >
                <div>
                  <UploadFile
                    id={NamesOfFieldEnum.PassportRegistrationFileId}
                    uploadedFiles={passportRegistrationFiles}
                    setUploadedFiles={setPassportRegistrationFiles}
                    isLoadingProps={isLoadingPassportRegistrationFile}
                    setFileid={setPassportRegistrationFileId}
                    count={1}
                    setError={setPassportRegistrationFilesError}
                    setIsFirstLoadingFile={setIsFirstLoadingFile}
                    contentText="Страница прописки"
                  />
                </div>
              </FormField>
            </div>
          </div>
        )}
      </div>
      <RulesFiles isPartnerTypeQuestionnaires />
      <Divider style={{ margin: 0 }} />
      <AcceptPolitics
        orgType={orgType}
        isAcceptPolitics={isAcceptPolitics}
        setIsAcceptPolitics={setIsAcceptPolitics}
        acceptPoliticsError={acceptPoliticsError}
        setAcceptPoliticsError={setAcceptPoliticsError}
      />
    </Wizard.Page>
  );
};
