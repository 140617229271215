import { Field, FormSpy } from "react-final-form";
import { TitlePage } from "~/components";
import { composeValidators, disabledValidate, required } from "~/utils/validate";
import { Divider, Form, Input, Select } from "antd";
import { FormField } from "~/pages/questionnaires/shared";
import { NamesOfFieldEnum } from "~/typings/types";
import { configTitleFieldsOfSelfEmployed, optionsSelectClientFrom } from "../configTitleFields";
import { FullWidthRowFormContainer } from "~/pages/questionnaires/shared/form-field";
import { V2Wizard } from "../../../../shared/wizard";

import styles from "./CommonSteps.module.scss";
import { FloatingLabelSelect } from "~/components/floating-label-select";

interface AdditionalDetailsProps {
  queryPromocode?: string
}

export const AdditionalDetails = ({ queryPromocode }: AdditionalDetailsProps) => {
  return (
    <V2Wizard.Page>
      <TitlePage name="Дополнительные сведения" number="5" />
      <Divider style={{ margin: 0 }} />
      <div className={styles.container}>
        <FullWidthRowFormContainer>
          <FormField
            titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.ClientFrom]}
            name={NamesOfFieldEnum.ClientFrom}
            required
            validate={composeValidators(required())}
          >
            <Field
              name={NamesOfFieldEnum.ClientFrom}
              subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
              validate={disabledValidate(composeValidators(required()))}
              component={FloatingLabelSelect}
              titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.ClientFrom]}
              required
              optionsSelect={optionsSelectClientFrom}
            />
          </FormField>
        </FullWidthRowFormContainer>

        <FullWidthRowFormContainer>
          <FormSpy subscription={{ values: true }}>
            {propsSpy => (
              <>
                {propsSpy.values[NamesOfFieldEnum.ClientFrom] === "other" && (
                  <FormField
                    titleField={""}
                    name={NamesOfFieldEnum.ClientFromOther}
                    isColon={false}
                    required
                    validate={composeValidators(required())}
                  />
                )}
              </>
            )}
          </FormSpy>
        </FullWidthRowFormContainer>

        <FullWidthRowFormContainer>
          <FormField
            titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.ClientPromocode]}
            name={NamesOfFieldEnum.ClientPromocode}
            disabled={!!queryPromocode}
             
          />
        </FullWidthRowFormContainer>

      </div>
    </V2Wizard.Page>
  );
};
