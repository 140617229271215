import { FormSpy } from "react-final-form";
import { TitlePage } from "~/components";
import {
  composeValidators,
  required,
  testRegExp
} from "~/utils/validate";
import { Divider } from "antd";
import {
  FormField,
  FormFieldBic
} from "~/pages/questionnaires/shared";
import { NamesOfFieldEnum } from "~/typings/types";
import { configTitleFieldsOfCompany, configTitleFieldsOfBank } from "../configTitleFields";
import { GET_BANK_INFO } from "~/pages/questionnaires/v1/customer/api/apiUrls";
import { V2Wizard } from "../../../../shared/wizard";
import { FullWidthRowFormContainer } from "~/pages/questionnaires/shared/form-field";

import styles from "./CommonSteps.module.scss";

interface CompanyDetailsProps {
  clientId: string;
}

export const CompanyDetails = ({ clientId }: CompanyDetailsProps) => {
  return (
    <V2Wizard.Page>
      <TitlePage name="Ваши реквизиты для вывода средств" number="3" description="Вставьте БИК банка - остальные данные мы загрузим автоматически" />
      <Divider style={{ margin: 0 }} />
      <div className={styles.container}>

        <FullWidthRowFormContainer>
          <FormSpy>
            {propsSpy => {
              return (
                <FormFieldBic
                  titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.BankBic]}
                  name={NamesOfFieldEnum.BankBic}
                  required
                  validate={composeValidators(
                    required(),
                    testRegExp(/^04(\d){7}$/g, 'БИК должен начинаться на "04" и состоять из 9 цифр')
                  )}
                  configNameFieldsModalSuggestion={configTitleFieldsOfBank}
                  propsSpy={propsSpy}
                  clientId={clientId}
                  tooltip="БИК — уникальный код банка. Он состоит из девяти цифр"
                  urlGetBankInfo={GET_BANK_INFO}
                />
              );
            }}
          </FormSpy>
        </FullWidthRowFormContainer>

        {
          // Костыль для валидации поля БИК, разобраться почему не выводится ошибка валидации БИК без этого скрытого поля
        }
        <div style={{ position: 'absolute', top: 0, left: 0, width: 0, height: 0, overflow: 'hidden' }}>
          <FormField
            titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.BankBic]}
            name={NamesOfFieldEnum.BankBic}
            required
            validate={composeValidators(
              required(),
              testRegExp(/^04(\d){7}$/g, 'БИК должен начинаться на "04" и состоять из 9 цифр')
            )}
          />
        </div>

        <FullWidthRowFormContainer>
          <FormField
            titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.BankName]}
            name={NamesOfFieldEnum.BankName}
            required
            validate={composeValidators(required())}

          />
        </FullWidthRowFormContainer>

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.SettlementAccount]}
          name={NamesOfFieldEnum.SettlementAccount}
          maxLength={20}
          mask="40799999999999999999"
          required
          validate={composeValidators(
            required(),
            testRegExp(
              /^407(\d){17}$/g,
              'Номер расчётного счёта должен начинаться на "407" и состоять из 20 цифр'
            )
          )}

        />
        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.CorrespondentAccount]}
          name={NamesOfFieldEnum.CorrespondentAccount}
          maxLength={20}
          mask="30199999999999999999"
          required
          validate={composeValidators(
            required(),
            testRegExp(
              /^301(\d){17}$/g,
              'Номер корреспондентского счёта должен начинаться на "301" и состоять из 20 цифр'
            )
          )}
        />

      </div>
    </V2Wizard.Page>
  );
};
