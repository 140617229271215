import { CSSProperties, useRef } from "react";
import cn from "classnames";
import { TooltipPlacement } from "antd/es/tooltip";
import { useHover } from "~/hooks/useHover";
import { CustomTooltip } from "./CustomTooltip";

import styles from "./TitleField.module.scss";

interface TitleField {
  name?: string;
  required?: boolean;
  tooltip?: string;
  isColon?: boolean;
  styleContainer?: CSSProperties;
  placement?: TooltipPlacement;
}

export const TitleField = ({
  name,
  required = false,
  tooltip = "",
  isColon = true,
  styleContainer,
  placement,
}: TitleField) => {
  const hoverRef = useRef<HTMLImageElement>(null);
  const isHover = useHover<HTMLImageElement>(hoverRef);

  return (
    <div className={styles.container} style={styleContainer}>
      <h3 className={cn({ [styles.text]: required })}>
        {name}
        {isColon && ":"}
      </h3>
      <CustomTooltip
        tooltip={tooltip}
        placement={placement}
      />
    </div>
  );
};
