import { Divider } from "antd";
import styles from "./SuccessPage.module.scss";

interface Props {
  isPartner?: boolean;
}

const ContentItem = ({ number, text }: { number: number; text: string }) => (
  <div className={styles.itemNext}>
    <div className={styles.numberNext}>
      <p>{number}</p>
    </div>
    <p className={styles.textNext}>{text}</p>
  </div>
);

const getContent = (isPartner?: boolean) => {
  if (isPartner) {
    return (
      <div className={styles.listNext}>
        <ContentItem
          number={1}
          text="В течение одного рабочего дня проверим данные, активируем промокод и свяжемся с вами. Ожидайте звонка или сообщения от менеджера Продамус."
        />
      </div>
    );
  }

  return (
    <div className={styles.listNext}>
      <ContentItem
        number={1}
        text="В течение одного дня на электронную почту собственника бизнеса, указанную в данной анкете, придет ссылка на оплату подключения."
      />
      <ContentItem
        number={2}
        text="После оплаты подключения в течение дня на эту же электронную почту придет доступ к личному кабинету + ссылка на инструкции по работе с системой + документы на подпись. Внимание! На этом этапе система будет в демо-режиме."
      />
      <ContentItem
        number={3}
        text="После получения Продамусом ответного письма со сканами (либо качественными фото) документов личный кабинет будет переведен в рабочий режим."
      />
    </div>
  );
};

const getSubtitle = (isPartner?: boolean) =>
  isPartner ? (
    <span>Мы проверим данные и свяжемся с вами в течение одного рабочего дня, ожидайте звонка менеджера.</span>
  ) : (
    <>
      <span>На ваш email было отправлено письмо.</span>
      <span>Для продолжения пройдите по ссылке в нем.</span>
    </>
  );

export const SuccessPage = ({ isPartner }: Props) => (
  <div className={styles.container}>
    <div className={styles.card} style={{ marginBottom: 20 }}>
      <div className={`${styles.image} ${styles.imageMargin}`} />
      <h2 className={styles.titleSuccess} id="title-success">Анкета отправлена!</h2>
      <div className={styles.subtitleSuccess}>{getSubtitle(isPartner)}</div>
    </div>

    <div className={styles.card}>
      <h3 className={styles.titleNext}>Что дальше?</h3>
      <Divider />
      {getContent(isPartner)}
      <Divider />

      <p className={styles.textEnd}>
        Можно будет принимать оплаты. Данные шаги будут дублироваться в приходящих на электронную почту письмах, так что
        вы точно ничего не пропустите. Желаем успешного использования системы! Надеемся, что сотрудничество принесет Вам
        только положительные эмоции и поможет приумножить ваши доходы!
      </p>
    </div>
  </div>
);
