import { forwardRef } from "react";
import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";

import styles from "./TitleField.module.scss";
import { Icon } from "../icon";

type Props = {
  tooltip: string;
  placement?: TooltipPlacement;
  visible?: boolean;
};

export const CustomTooltip = forwardRef<HTMLSpanElement, Props>(({ tooltip, placement = "top", visible = true }, ref) => {
  if (!tooltip || !visible) return null;

  return (
    <Tooltip
      placement={placement}
      title={tooltip}
      arrow={false}
      overlayInnerStyle={{
        backgroundColor: "#8C8E94",
        padding: 20,
        fontSize: 14,
        fontFamily: "Roboto",
        lineHeight: "24px",
      }}
    >
      <span ref={ref}>
        <Icon name="circle-help" className={styles.question} />
      </span>
    </Tooltip>
  );
});
