import {
  NameStepsEnumCustomerV2,
  OrgTypeEnum,
  NamesOfFieldEnum,
  ClientId,
  SharedTypeRequestFieldsCustomerV2,
  AccountDetailsFormFieldsCustomerV2,
  CompanyDetailsFormFieldsCustomerV2,
  PayformTechDetailsFormFieldsCustomerV2,
  FormDataCustomerV2,
  AdditionalDetailsFormFieldsCustomerV2,
} from "~/typings/types";
import { getValuesFromObject } from "~/utils/getValuesFromObject";
import { getWithPrefixPhone } from "~/utils/getWithPrefixPhone";
import {
  postDraftCompanyDetails,
  postDraftAccountDetails,
  postDraftPayformTechDetails,
  postDraftAdditionalDetails,
  ReqPostArguments,
  ResPost
} from "./apiMethods";
import { getFieldNameWithCondition, getFormattedDateForBackend } from "~/utils";
import { defaultAxiosRequestConfig } from "~/api/apiMethods";

type RequestFunction = ({
  data,
  config
}: ReqPostArguments<SharedTypeRequestFieldsCustomerV2>) => Promise<ResPost>;
type RequestConfig = Omit<Record<NameStepsEnumCustomerV2, RequestFunction>, NameStepsEnumCustomerV2.AttachmentsDetails>;

const requestConfig: RequestConfig = {
  [NameStepsEnumCustomerV2.CompanyDetails]: postDraftCompanyDetails,
  [NameStepsEnumCustomerV2.AccountDetails]: postDraftAccountDetails,
  [NameStepsEnumCustomerV2.PayformTechDetails]: postDraftPayformTechDetails,
  [NameStepsEnumCustomerV2.AdditionalDetails]: postDraftAdditionalDetails
};

interface ApiService {
  values: FormDataCustomerV2;
  pageId: Exclude<NameStepsEnumCustomerV2, NameStepsEnumCustomerV2.AttachmentsDetails>;
  clientId: ClientId;
  orgType: OrgTypeEnum;
  logoFileId?: any;
}

export const apiServiceCustomer = async ({ values, pageId, clientId, orgType, logoFileId }: ApiService) =>
  await new Promise((resolve, reject) => {
    let reqData: any; // TODO: fixed any type
    if (pageId === NameStepsEnumCustomerV2.AccountDetails) {
      reqData = {
        id: clientId,
        fields: {
          [NamesOfFieldEnum.OrgType]: orgType,
          [NamesOfFieldEnum.CrmPhone]: getWithPrefixPhone(values?.[NamesOfFieldEnum.CrmPhone]),
          [NamesOfFieldEnum.LprPhone]: getWithPrefixPhone(values?.[NamesOfFieldEnum.LprPhone]),
          [NamesOfFieldEnum.BuhPhone]: getWithPrefixPhone(values?.[NamesOfFieldEnum.BuhPhone]),
          [NamesOfFieldEnum.TechPhone]: getWithPrefixPhone(values?.[NamesOfFieldEnum.TechPhone]),
          [NamesOfFieldEnum.CrmBirthday]: getFormattedDateForBackend(values?.[NamesOfFieldEnum.CrmBirthday]),
          [NamesOfFieldEnum.LrpProxyDocDate]: getFormattedDateForBackend(values?.[NamesOfFieldEnum.LrpProxyDocDate]),
          ...getValuesFromObject<
            Omit<
              AccountDetailsFormFieldsCustomerV2,
              | NamesOfFieldEnum.OrgType
              | NamesOfFieldEnum.CrmPhone
              | NamesOfFieldEnum.LprPhone
              | NamesOfFieldEnum.BuhPhone
              | NamesOfFieldEnum.TechPhone
              | NamesOfFieldEnum.CrmBirthday
              | NamesOfFieldEnum.LrpProxyDocDate
            >
          >({
            object: values,
            values: [
              NamesOfFieldEnum.CrmNameFirst,
              NamesOfFieldEnum.CrmNameLast,
              NamesOfFieldEnum.CrmNamePatron,
              NamesOfFieldEnum.CrmEmail,
              NamesOfFieldEnum.Inn,
              NamesOfFieldEnum.LprName,
              NamesOfFieldEnum.BuhName,
              NamesOfFieldEnum.TechName,
              NamesOfFieldEnum.LrpProxyDocNumber,
              NamesOfFieldEnum.LegalAddress,
              NamesOfFieldEnum.PostalAddress,
              getFieldNameWithCondition(orgType === OrgTypeEnum.Company, NamesOfFieldEnum.Ogrn),
              getFieldNameWithCondition(
                orgType === OrgTypeEnum.IndividualEntrepreneur,
                NamesOfFieldEnum.VatMode
              ),
              getFieldNameWithCondition(
                orgType === OrgTypeEnum.IndividualEntrepreneur,
                NamesOfFieldEnum.Ogrnip
              ),
            ]
          })
        }
      };
    }

    if (pageId === NameStepsEnumCustomerV2.CompanyDetails) {
      reqData = {
        id: clientId,
        fields: {
          [NamesOfFieldEnum.OrgType]: orgType,
          ...getValuesFromObject<Omit<CompanyDetailsFormFieldsCustomerV2, NamesOfFieldEnum.OrgType>>({
            object: values,
            values: [
              NamesOfFieldEnum.BankName,
              NamesOfFieldEnum.SettlementAccount,
              NamesOfFieldEnum.CorrespondentAccount,
              NamesOfFieldEnum.BankBic,
            ]
          })
        }
      };
    }


    if (pageId === NameStepsEnumCustomerV2.PayformTechDetails) {
      reqData = {
        id: clientId,
        fields: {
          [NamesOfFieldEnum.OrgType]: orgType,
          [NamesOfFieldEnum.LogoMainFileId]: logoFileId?.length ? logoFileId[0] : null,
          [NamesOfFieldEnum.PayformPhone]: getWithPrefixPhone(values?.[NamesOfFieldEnum.PayformPhone]),
          [NamesOfFieldEnum.PayformName]: `https://${values?.payform_name}.payform.ru`,
          ...getValuesFromObject<
            Omit<
              PayformTechDetailsFormFieldsCustomerV2,
              NamesOfFieldEnum.OrgType
              | NamesOfFieldEnum.PayformName
              | NamesOfFieldEnum.PayformPhone
              | NamesOfFieldEnum.LogoMainFileId
            >
          >({
            object: values,
            values: [
              NamesOfFieldEnum.ProductType,
              NamesOfFieldEnum.ProductName,
              NamesOfFieldEnum.ProductName,
              NamesOfFieldEnum.ProductDescription,
              NamesOfFieldEnum.PayformEmail,
              NamesOfFieldEnum.PayformLinkVk,
              NamesOfFieldEnum.PayformLinkTg,
              NamesOfFieldEnum.PayformLinkInstagram,
              NamesOfFieldEnum.PayformLinkOther,
              NamesOfFieldEnum.PayformLinkSite,
              NamesOfFieldEnum.PayformPaymentMethods,
              NamesOfFieldEnum.IsHaveEducationalLicense,
            ]
          })
        }
      };
    }

    if (pageId === NameStepsEnumCustomerV2.AdditionalDetails) {
      reqData = {
        id: clientId,
        fields: {
          [NamesOfFieldEnum.OrgType]: orgType,
          ...getValuesFromObject<Omit<AdditionalDetailsFormFieldsCustomerV2, NamesOfFieldEnum.OrgType>>({
            object: values,
            values: [
              NamesOfFieldEnum.ClientFrom,
              NamesOfFieldEnum.ClientFromOther,
              NamesOfFieldEnum.ClientPromocode,
              NamesOfFieldEnum.PaymentMethodProdamusSubscription,
            ]
          })
        }
      };
    }



    requestConfig?.[pageId]({ data: reqData, config: defaultAxiosRequestConfig })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        console.log("err requestConfig", err);
        reject(err);
      });
  });
