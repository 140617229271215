import cn from "classnames";
import { ERROR_FIELD_ } from "~/configs/consts";

import styles from "./RadioField.module.scss";

export const RadioField = ({ input, children, meta, disabled, classNameInput, classNameWrapper }: any) => {
  const hasError = (meta?.error || meta?.submitError) && meta?.submitFailed;
  const inputId = hasError ? `${ERROR_FIELD_}${input.name}` : undefined;

  return (
    <label
      className={cn(styles.container, classNameWrapper, {
        [styles.disabledLabel]: disabled
      })}
    >
      <input
        type="radio"
        {...input}
        id={inputId}
        disabled={disabled}
        className={cn(classNameInput, {
          [styles.inputDisabled]: disabled
        })}
      />
      {children}
    </label>
  );
};
