import { ReactNode, useEffect, useRef, useState } from "react";
import cn from "classnames";
import { Field, useFormState, useForm } from "react-final-form";
import { Button, Select, Input, Space, InputRef, Tooltip } from "antd";
import {
  InstagramOutlined,
  GlobalOutlined,
  DeleteOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import { DescriptionCollapseField, ErrorField, FloatingLabelInput, Icon, TitleField } from "~/components";
import { PayformLinkType, NamesOfFieldEnum } from "~/typings/types";
import { extractExistingFields } from "~/utils/extractExistingFields";
import { composeValidators, disabledValidate, validateDependentFields } from "~/utils";

import styles from "./SocialLinkSelectField.module.scss";
import './styles.scss';
import { ERROR_FIELD_ } from "~/configs/consts";
import { CustomTooltip } from "~/components/title-field/CustomTooltip";

const { Option } = Select;

const configPlatform: Record<PayformLinkType, PlatformConfig> = {
  [NamesOfFieldEnum.PayformLinkVk]: {
    title: "ВКонтакте",
    icon: <Icon name="vk" className={styles.iconSocial} />,
  },
  [NamesOfFieldEnum.PayformLinkTg]: {
    title: "Telegram",
    icon: <Icon name="tg" className={cn(styles.iconSocial, styles.iconTg)} />,
  },
  [NamesOfFieldEnum.PayformLinkInstagram]: {
    title: "Instagram",
    icon: <InstagramOutlined />,
  },
  [NamesOfFieldEnum.PayformLinkOther]: {
    title: "Сайт",
    icon: <GlobalOutlined />,
  },
  [NamesOfFieldEnum.PayformLinkSite]: {
    title: "Другое",
    icon: <AppstoreOutlined style={{ marginTop: 4 }} />,
  },
};

interface PlatformConfig {
  title: string;
  icon: React.ReactNode;
}

interface SocialField {
  platform: PayformLinkType;
  link: string;
}

interface SocialLinkSelectField {
  name: string;
  titleField?: string;
  fieldsLinks: string[]; // поля которые нужно отправлять/получать на бек
  descriptionContent?: ReactNode;
  required?: boolean;
}

export const SocialLinkSelectField = ({
  name,
  titleField,
  fieldsLinks,
  descriptionContent,
  required,
  ...props
}: SocialLinkSelectField) => {
  const [socialFields, setSocialFields] = useState<SocialField[]>([]);
  const [isFocusedSelect, setIsFocusedSelect] = useState(false);
  const inputRefs = useRef<Record<PayformLinkType, InputRef | null>>(
    Object.keys(NamesOfFieldEnum).reduce((acc, key) => {
      acc[key as PayformLinkType] = null;
      return acc;
    }, {} as Record<PayformLinkType, InputRef | null>)
  );

  const form = useForm();
  const formState = useFormState();
  const initValues = formState?.values;

  useEffect(() => {
    const backendDataByFieldsLinks = extractExistingFields(fieldsLinks, initValues);

    const prefilledFields: SocialField[] = Object.entries(backendDataByFieldsLinks)
      .map(([platform, value]) => {
        if (platform && value) {
          setIsFocusedSelect(true);
          return {
            platform,
            link: value as string,
          };
        }
        return null;
      })
      .filter(Boolean) as SocialField[];

    setSocialFields(prefilledFields);
  }, []);

  const handleAddField = (platform: PayformLinkType) => {
    if (typeof platform === "string") {
      const newField: SocialField = {
        platform,
        link: "",
      };
      setSocialFields((prevFields) => [...prevFields, newField]);
      setIsFocusedSelect(true);

      setTimeout(() => {
        inputRefs?.current && inputRefs.current[platform]?.focus();
      }, 0);
    }
  };

  const handleRemoveField = (platform: PayformLinkType) => {
    setSocialFields(socialFields.filter((field) => field.platform !== platform));
    form.change(platform, undefined);
  };

  return (
    <>
      {/* {titleField && <TitleField
        name={titleField}
        required
        tooltip="Интернет-ресурс обязательно должен быть открытым и доступным. Также он должен содержать описание услуг или продуктов, за которые вы планируете принимать оплату через Prodamus. Если ресурсов несколько, укажите их через запятую"
      />} */}

      <Field
        name="payform-link-select"
        subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
        validate={disabledValidate(
          composeValidators(
            validateDependentFields(
              fieldsLinks,
              "Один из источников должен быть обязательно заполнен"
            )
          )
        )}
      >
        {({ input, meta: { submitFailed, error, submitError } }) => {
          const hasError = (error || submitError) && submitFailed;
          const inputId = hasError ? `${ERROR_FIELD_ + input.name}` : undefined;

          return (
            <Tooltip
              trigger="hover"
              placement="top"
              title="Интернет-ресурс обязательно должен быть открытым и доступным. Также он должен содержать описание услуг или продуктов, за которые вы планируете принимать оплату через Prodamus. Если ресурсов несколько, укажите их через запятую"
              arrow={false}
              overlayInnerStyle={{
                backgroundColor: "#8C8E94",
                padding: 20,
                fontSize: 14,
                fontFamily: "Roboto",
                lineHeight: "24px",
              }}
            >
              <Select
                id={inputId}
                className="custom-select"
                value={null}
                placeholder={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: 'auto'}} className={cn({
                      [styles.text]: required,
                    })}>
                      {titleField}
                    </span>
                    <CustomTooltip tooltip={'Дефолтный текст, его не видно, но нужен для отрисовки иконки'} />
                  </div>

                }
                placement="bottomLeft"
                style={{ width: "100%", height: 56, borderRadius: 12 }}
                onChange={(value: PayformLinkType) => handleAddField(value)}
                dropdownRender={(menu) => {
                  return (
                    <>
                      {menu}
                      <Space style={{ padding: "10px", justifyContent: "center" }}>
                        <span style={{ fontSize: "12px", color: "#8c8c8c" }}>
                          Добавьте ссылку для выбранной соцсети
                        </span>
                      </Space>
                    </>
                  )
                }}
              >
                {Object.entries(configPlatform)
                  .map(([platformKey, config]) => {
                    const isAdded = socialFields.some(
                      (field) => field.platform === platformKey
                    );

                    return (
                      <Option key={platformKey} value={platformKey} disabled={isAdded}>
                        <Space>
                          {config.icon}
                          {config.title}
                        </Space>
                      </Option>
                    );
                  })}
              </Select>
            </Tooltip>
          )
        }}
      </Field>
      <ErrorField name="payform-link-select" classNameContainer={styles.containerErrorField} />

      {socialFields?.length ? (
        <div style={{ marginTop: 10 }}>
          {socialFields.map((field) => (
            <Field
              key={field.platform}
              name={field.platform}
              initialValue={field.link}
              subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
              validate={disabledValidate(
                composeValidators(
                  validateDependentFields(
                    fieldsLinks,
                    "Один из источников должен быть обязательно заполнен"
                  )
                )
              )}
              render={({ input, meta: { submitFailed, error, submitError } }) => {
                const hasError = (error || submitError) && submitFailed;
                const inputId = hasError ? `${ERROR_FIELD_ + input.name}` : undefined;

                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginBottom: "20px",
                    }}
                  >
                    <Space>{configPlatform[field.platform].icon}</Space>
                    <FloatingLabelInput
                      {...input}
                      id={inputId}
                      ref={(el: any) => (inputRefs.current[field.platform] = el)}
                      className={cn(styles.inputCustom, {
                        [styles.inputCustomError]: hasError,
                      })}
                      titleField={`Введите ссылку для ${configPlatform[field.platform].title}`}
                      style={{ flex: 1 }}
                    />
                    <Button
                      danger
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemoveField(field.platform)}
                    />
                  </div>
                );
              }}
            />
          ))}
        </div>
      ) : null}

      <DescriptionCollapseField content={descriptionContent} trigger={isFocusedSelect} />

    </>
  );
};
