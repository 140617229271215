import React, { useState } from "react";
import { Select } from "antd";
import { Field } from "react-final-form";
import cn from "classnames";

import './FloatingLabelSelect.css'
import styles from "./FloatingLabelSelect.module.scss";

const { Option } = Select;

interface FloatingLabelSelectProps {
	input: any;
	meta: any;
	titleField: string;
	required?: boolean;
	optionsSelect: { value: string; label: string }[];
	disabled?: boolean;
}

export const FloatingLabelSelect: React.FC<FloatingLabelSelectProps> = ({
	input,
	meta,
	titleField,
	required = false,
	optionsSelect,
	disabled = false,
}) => {
	const [isFocused, setIsFocused] = useState<boolean>(false);
	const [isHovered, setIsHovered] = useState<boolean>(false);

	const hasError = (meta?.error || meta?.submitError) && meta?.submitFailed;
	const inputId = hasError ? `ERROR_FIELD_${input.name}` : undefined;

	const handleFocus = () => setIsFocused(true);
	const handleBlur = (e: React.FocusEvent<HTMLSelectElement>) => {
		setIsFocused(false);
		input.onBlur(e);
	};

	const handleChange = (value: string) => {
		input.onChange(value);
	};

	return (
		<div
			className={cn('floatingLabelSelect', styles.selectWrapper)}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			{/* Плавающий лейбл */}
			<label
				htmlFor={inputId}
				className={cn(styles.label, {
					[styles.labelFocused]: isFocused || !!input.value,
					[styles.labelError]: hasError,
				})}
			>
				{titleField}
				{required && "*"}
			</label>

			<Select
				{...input}
				id={inputId}
				disabled={disabled}
				placeholder="Выберите вариант"
				value={input.value}
				onChange={handleChange}
				onFocus={handleFocus}
				onBlur={handleBlur}
				allowClear
				className={cn(styles.select, { [styles.error]: hasError })}
			>
				{optionsSelect.map((option) => (
					<Option key={option.value} value={option.value}>
						{option.label}
					</Option>
				))}
			</Select>
		</div>
	);
};

