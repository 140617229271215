import { Dispatch, SetStateAction } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Spin } from "antd";
import cn from "classnames";
import { TitlePage } from "~/components";
import {
  FormField,
  Wizard,
  UploadFile,
  AcceptPolitics
} from "~/pages/questionnaires/shared";
import { AttachmentsDetailsError, NamesOfFieldEnum, OrgTypeEnum } from "~/typings/types";
import { configTitleFieldsOfCompany } from "../configTitleFields";
import { RulesFiles } from "../../../../shared/rules-files";

import "~/pages/questionnaires/shared/styles.css";
import styles from "~/pages/questionnaires/shared/Shared.module.scss";
import stylesStep from "./CommonSteps.module.scss";

interface Props {
  isLoadingMount: boolean;
  isLoadingLogo: boolean;
  isLoadingPassport: boolean;
  logoFiles: File[];
  setLogoFileId: Dispatch<SetStateAction<number[]>>;
  setLogoFiles: Dispatch<File[]>;
  passportFiles: File[];
  setPassportFileId: Dispatch<SetStateAction<number[]>>;
  setPassportFiles: Dispatch<File[]>;
  logoError: AttachmentsDetailsError;
  passportError: AttachmentsDetailsError;
  setLogoError: Dispatch<AttachmentsDetailsError>;
  setPassportError: Dispatch<AttachmentsDetailsError>;
  COUNT_UPLOAD_LOGO_FILE?: number;
  COUNT_UPLOAD_PASSPORT_FILE?: number;
  isAcceptPolitics: boolean;
  setIsAcceptPolitics: Dispatch<SetStateAction<boolean>>;
  acceptPoliticsError: AttachmentsDetailsError;
  setAcceptPoliticsError: Dispatch<SetStateAction<AttachmentsDetailsError>>;
  setIsFirstLoadingFile: Dispatch<boolean>;
  orgType?: OrgTypeEnum;
  COUNT_DOCUMENT_AGREEMENT1_FILE?: number;
  COUNT_DOCUMENT_AGREEMENT2_FILE?: number;
  isLoadingDocumentAgreement1File: boolean;
  documentAgreement1Files: File[];
  setDocumentAgreement1FileId: Dispatch<SetStateAction<number[]>>;
  setDocumentAgreement1Files: Dispatch<File[]>;
  documentAgreement1Error: AttachmentsDetailsError;
  setDocumentAgreement1Error: Dispatch<AttachmentsDetailsError>;
  isLoadingDocumentAgreement2File: boolean;
  documentAgreement2Files: File[];
  setDocumentAgreement2FileId: Dispatch<SetStateAction<number[]>>;
  setDocumentAgreement2Files: Dispatch<File[]>;
  documentAgreement2Error: AttachmentsDetailsError;
  setDocumentAgreement2Error: Dispatch<AttachmentsDetailsError>;
}

export const AttachmentsDetails = ({
  isLoadingMount,
  isAcceptPolitics,
  setIsAcceptPolitics,
  acceptPoliticsError,
  setAcceptPoliticsError,
  setIsFirstLoadingFile,
  orgType,
  COUNT_DOCUMENT_AGREEMENT1_FILE,
  isLoadingDocumentAgreement1File,
  documentAgreement1Files,
  setDocumentAgreement1FileId,
  setDocumentAgreement1Files,
  documentAgreement1Error,
  setDocumentAgreement1Error,
}: Props) => {
  return (
    <Wizard.Page>
      <TitlePage name="Фуух. Осталось совсем чуть-чуть" number="6" isRenderIconHappy />
      <Divider style={{ margin: 0 }} />
      <div className={cn(styles.container, styles.containerUpload)}>
        {isLoadingMount ? (
          <div className={styles.containerLoader}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#3d935e" }} spin />} />
          </div>
        ) : (
          <>
            <FormField
              titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.DocumentAgreement1FileId]}
              name={NamesOfFieldEnum.DocumentAgreement1FileId}
              styleContainer={{ minHeight: 190 }}
              renderError={documentAgreement1Error}
              shouldRenderTitleAboveField
            >
              <div>
                <p style={{ fontSize: '12px', color: '#8c8c8c', marginBottom: 4 }}>ВАЖНО:</p>
                <ul className={stylesStep.listRequired}>
                  <li>Если вы самозанятый и оказываете услуги совместно с третьим лицом, то необходимо предоставить договор услуг.</li>
                  <li>Чужие услуги и товары к реализации запрещены, но возможно привлечение третьих лиц через договор.</li>
                </ul>
                <UploadFile
                  id={NamesOfFieldEnum.DocumentAgreement1FileId}
                  uploadedFiles={documentAgreement1Files}
                  setUploadedFiles={setDocumentAgreement1Files}
                  isLoadingProps={isLoadingDocumentAgreement1File}
                  setFileid={setDocumentAgreement1FileId}
                  count={1}
                  setError={setDocumentAgreement1Error}
                  setIsFirstLoadingFile={setIsFirstLoadingFile}
                />
              </div>
            </FormField>
          </>
        )}
      </div>
      <RulesFiles />
      <Divider style={{ margin: 0 }} />
      <AcceptPolitics
        orgType={orgType}
        isAcceptPolitics={isAcceptPolitics}
        setIsAcceptPolitics={setIsAcceptPolitics}
        acceptPoliticsError={acceptPoliticsError}
        setAcceptPoliticsError={setAcceptPoliticsError}
      />
    </Wizard.Page>
  );
};
