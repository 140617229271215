import { Field } from "react-final-form";
import cn from "classnames";

import styles from "./ErrorField.module.scss";

export type RenderError = {
  has: 1 | 0;
  message: string;
  color?: string;
}

interface ErrorField {
  name: string;
  renderError?: RenderError | null;
  classNameContainer?: string;
}

export const ErrorField = ({ name, renderError, classNameContainer }: ErrorField) => (
  <Field
    name={name}
    subscription={{ submitFailed: true, error: true, submitError: true }}
    render={({ meta: { submitFailed, error, submitError } }) => {
      const isError =
        ((error || submitError) && submitFailed) || (renderError && renderError.has);
      const errorMessage = renderError?.message || error || submitError;

      return (
        <div className={cn(styles.container, classNameContainer)}>
          {isError && (
            <p className={styles.text} style={{ color: renderError?.color, display: "flex", alignItems: "flex-start", flexDirection: typeof errorMessage === "string" ? 'column' : 'row' }}>
              {typeof errorMessage === "string"
                ? errorMessage.split("\n").map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))
                : errorMessage}
            </p>
          )}
        </div>
      );
    }}
  />
);

